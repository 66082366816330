.sectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100vh;
}

.sectionImage {
  max-width: 400px;
  height: auto;
}
