.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* 섹션 내부 컨테이너 */
.sectionContainer {
  margin-top: -150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

/* 이미지 컨테이너 */
.imageContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 80%;
  position: relative;
}

/* 이미지 크기 조정 */
.sectionImage {
  width: 60%;
  height: auto;
}

/* 슬라이드 애니메이션 */
@keyframes slideRight {
  from {
    transform: translateX(100%);
    opacity: 0.8;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(-100%);
    opacity: 0.8;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: slideRight 0.5s ease-in-out;
}

.slide-in-left {
  animation: slideLeft 0.5s ease-in-out;
}

/* 오른쪽 아래 버튼 컨테이너 */
.bottomNavButtons {
  position: absolute;
  bottom: 50px;
  right: 50px;
  display: flex;
  gap: 10px;
}

.navButton {
  background: white;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background: rgba(0, 0, 0, 0.8);
  color: white;
}
