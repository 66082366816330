/* 전체 컨테이너 */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background: #f9fafb; /* 배경색 피클플러스 스타일 */
  padding: 40px 20px;
}

/* 카드 스타일 */
.card {
  width: 50%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

/* 타이틀 스타일 */
.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #222;
}

/* 아코디언 스타일 */
.accordion {
  width: 100%;
}

/* 개별 아이템 */
.item {
  margin-bottom: 8px;
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff; /* 질문 부분 흰색 */
  box-shadow: none;
  border: 1px solid #e0e0e0; /* 피클플러스 같은 회색 테두리 */
  transition: all 0.3s ease-in-out;
}

/* 질문 버튼 */
.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 18px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background: #ffffff;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

/* 아이콘 스타일 */
.chevron {
  width: 22px;
  height: 22px;
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

/* 아코디언 내용 */
.content {
  padding: 14px 18px;
  background: #ffffff;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 550;
  color: #0f0f0f;
  border-top: 1px solid #e0e0e0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  font-family: "NanumSquareNeoLight";
  overflow: hidden;
}

.accordion [data-state="open"] .content {
  max-height: 300px;
  opacity: 1;
}
